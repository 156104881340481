<script setup lang="ts">
import TradeConfirmation from './TradeConfirmation.vue';
import TradeFormGroup from './forms/TradeFormGroup.vue';
import {
  CreateTradeRequest,
  Beneficiary,
  beneficiaryName,
  getBuyCcy,
  VersionedObject,
  TradeFundingDetails,
  CreatePaymentRequest,
  AmountType,
  PaymentErrorCodes,
  TradingManagerErrorCodes,
  TradeStatus,
  Trade,
} from 'ah-api-gateways';
import { TradeDestination } from './forms/tradeDestination';
import { TradeFundsDestination, TradeDetails } from '../models/trade';
import { HttpError, waitForEntityChange } from 'ah-requests';
import { QuotePriceRequest } from '../requests/pricingRequest';
import { BModal } from 'bootstrap-vue';
import { mergeMap } from 'rxjs/operators';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useRoute, useRouter } from 'vue-router/composables';
import { useAhTradesState } from '..';
import { reactive, watch, computed, ref } from 'vue';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { aggregatedPaymentReviewRoute } from '../composables/aggregatedPaymentLimitChecker';
import { useToast } from 'ah-common-lib/src/toast';
import { BankType } from 'ah-api-gateways/models/tradingDesk/bankAccounts';

const confirmationModal = ref<InstanceType<typeof BModal> | null>(null);
const routeLeaveConfirmModal = ref<InstanceType<typeof BModal> | null>(null);

const props = withDefaults(
  defineProps<{
    /**
     * Title to show above flow markup
     */
    title?: string;
    /**
     * Whether the trade creator is in a modal (will affect styling only)
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    inModal?: boolean | string;
    /**
     * Possible set target beneficiary for the trade
     *
     * If set, user cannot choose the target of the trade,
     * and target currencies are limited to the accounts defined in this beneficiary
     */
    beneficiary?: Beneficiary;
    /**
     * Whether to force keeping funds
     *
     * If set, user cannot choose the target  of the trade, and will always keep them
     * (i.e. move to another wallet)
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    forceKeep?: boolean | string;
    /**
     * Whether to force showing destination form
     *
     * If set, destination form will not be hidden, even if forceKeep is true
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    forceShowDestination?: boolean | string;
    /**
     * Whether to force a spot.
     *
     * If set, user cannot choose the type of trade
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    forceImmediateSpot?: boolean | string;
    /**
     * Whether to sync trade funds.
     *
     * If set, trades will be synchronized, and user will see an alert to that effect
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    syncTradeFunds?: boolean | string;
    /**
     * Possible array of allowed buy currencies
     *
     * If set, user will only be able to choose from the currencies listed as a buy currency
     */
    allowedBuyCurrencies?: string[];
    /**
     * Possible array of allowed sell currencies
     *
     * If set, user will only be able to choose from the currencies listed as a sell currency
     */
    allowedSellCurrencies?: string[];
    /**
     * Whether the current trade is supporting a direct payment.
     *
     * If true:
     * - beneficiaries list will not be displayed
     * - `beneficiary` prop is expected to be non-null
     * - trade date will not affect payment date, and will be set independently
     */
    isPayment?: string | boolean;
    /**
     * Whether to verify limits
     *
     * If true:
     * - client will verify limits
     */
    verifyLimits?: string | boolean;
    /**
     * Whether to show cost transparency
     *
     * If true:
     * - client will show cost transparency
     */
    costTransparency?: string | boolean;
    bankType?: BankType;
  }>(),
  {
    title: 'Create new FX Trade',
    inModal: false,
    forceKeep: false,
    forceShowDestination: false,
    forceImmediateSpot: false,
    verifyLimits: false,
    costTransparency: false,
    isPayment: false,
  }
);

const emit = defineEmits<{
  (e: 'update:stage', value: 'tradeDetails' | 'tradeConfirmation'): void;
  (e: 'trade-cancelled'): void;
  (e: 'flow-completed'): void;
  (e: 'trade-created', value: VersionedObject): void;
  (e: 'update:beneficiary', value: Beneficiary): void;
}>();

const state = reactive<{
  stage: 'tradeDetails' | 'tradeConfirmation';
  tradeDetails: TradeDetails | null;
  tradePrice: QuotePriceRequest | null;
  tradeDestination: TradeDestination | null;
  tradeFundingDetails: Partial<TradeFundingDetails>;
  confirmShowing: boolean;
  routeLeaveConfirmShowing: boolean;
  walletBalanceUsageRequired: boolean | null;
}>({
  stage: 'tradeDetails',
  tradeDetails: null,
  tradePrice: null,
  tradeDestination: null,
  tradeFundingDetails: {},
  confirmShowing: false,
  routeLeaveConfirmShowing: false,
  walletBalanceUsageRequired: null,
});

const requestManager = useRequestManager({
  exposeToParent: false,
});

const costTransparencyCurrency = ref<AmountType>(AmountType.BUY);

const tradeState = useAhTradesState();

const onBehalfOfClient = useOnBehalfOf();

const toast = useToast();

const route = useRoute();
const router = useRouter();

function created() {
  if (route.query.tradeDetails || route.query.tradePrice) {
    try {
      if (route.query.tradeDetails) {
        state.tradeDetails = JSON.parse(route.query.tradeDetails as string);
      }
      if (route.query.tradePrice) {
        state.tradePrice = JSON.parse(route.query.tradePrice as string);
      }
    } catch (e) {}
    router.replace({ path: route.path });
  }

  tradeState.store.useSettingsStore().loadPaymentRails();
}

created();

/**
 * Gets all "load" states on the request manager (including children)
 */
const loadRequestStates = computed(() =>
  requestManager.getAllRequests((key) => key.startsWith('load'), true).map((r) => r.state)
);

const isTrade = computed(
  () => !state.tradeDetails || state.tradeDetails.sellCurrency !== state.tradeDetails.buyCurrency
);

const isInModal = computed(() => props.inModal !== false);

const isStackedView = computed(() => tradeState.mediaQuery.is('smDown') !== false);

const isForceKeep = computed(() => props.forceKeep !== false);

const isForceShowDestination = computed(() => props.forceShowDestination !== false);

const isSyncTradeFunds = computed(() => props.syncTradeFunds !== false);

const isVerifyingLimits = computed(() => props.verifyLimits !== false);

const isForceImmediateSpot = computed(() => props.forceImmediateSpot !== false);

const isOnBehalfOf = computed(() => onBehalfOfClient.value);

const isScheduledPayment = computed(() => props.isPayment !== false);

const warningMessage = computed(() => {
  if (isOnBehalfOf.value) {
    return `You are currently creating a new FX Trade for ${onBehalfOfClient.value?.name}. Are you sure you want to exit On Behalf Of mode?`;
  }
  return 'Are you sure you want to leave this page? Unsaved changes will be lost.';
});

const oboTradeConfirmationMessage = computed(() => {
  if (state.tradeDetails && props.beneficiary && onBehalfOfClient.value) {
    const beneficiary = beneficiaryName(props.beneficiary!);

    return `You are sending ${state.tradeDetails.buyCurrency} ${formatCurrencyValue(
      state.tradeDetails.amount!
    )} to ${beneficiary} on behalf of
    ${onBehalfOfClient.value.name}.`;
  }
  return undefined;
});

function onRetryLoad() {
  requestManager.getAllRequestsInState('error', true).forEach((r) => r.managerData.retryRequest(r.request));
}

function onStageChange() {
  emit('update:stage', state.stage);
}

watch(
  () => state.stage,
  () => {
    onStageChange();
  },
  { immediate: true }
);

function onTradeCancelled() {
  emit('trade-cancelled');
}

function onRevertToForm() {
  state.stage = 'tradeDetails';
}

function onOutdatedPrice() {
  toast?.error('Price request is outdated, please re-submit values to continue.');
  onRevertToForm();
}

function onTradeSubmitted() {
  if (isTrade.value) {
    state.stage = 'tradeConfirmation';
  } else {
    requestManager.manager.newPromise(
      'createTrade',
      tradeState.services.payments
        .createPayment(
          {
            walletId: state.tradeDetails!.walletId!,
            amount: state.tradeDetails!.amount!,
            beneficiaryId: props.beneficiary!.id,
            reference: state.tradeDestination?.reference || undefined,
            executionDate: state.tradeDestination?.executionDate,
            chargeType: state.tradeDestination?.chargeType,
            reason: state.tradeDestination?.reason || undefined,
          },
          onBehalfOfClient.value?.id
        )
        .toPromise()
        .then(() => tradeState.store.useWalletsStore().waitForWalletChange({ id: state.tradeDetails!.walletId! }))
        .then(() => {
          tradeState.toast.success('Funds sent successfully');
          state.tradePrice = null;
          state.tradeDestination = null;
          requestManager.manager.clear();
          state.stage = 'tradeDetails';
          emit('flow-completed');
        })
    );
  }
}

function clearFieldsAndFinish(version: VersionedObject) {
  tradeState.store.useWalletsStore().expireCollaterals();
  state.tradePrice = null;
  state.tradeDestination = null;
  state.tradeDetails = null;
  requestManager.manager.clear();
  emit('trade-created', version);
  state.stage = 'tradeDetails';
  emit('flow-completed');
}

function scheduleTradePayment(payload: CreatePaymentRequest, tradeVersionedObject: VersionedObject) {
  if (state.tradePrice && state.tradePrice.response) {
    const buyCurrency = getBuyCcy(state.tradePrice.response);

    requestManager.manager.newPromise(
      'schedulePayment',
      tradeState.store
        .useWalletsStore()
        .loadCurrencyWallet({
          currency: buyCurrency.currency,
          owner: onBehalfOfClient.value ? { id: onBehalfOfClient.value.id, isPartner: false } : undefined,
        })
        .then((buyWallet) => {
          if (buyWallet) {
            payload.walletId = buyWallet!.id;
            payload.amount = buyCurrency.clientAmount;
            return tradeState.services.payments
              .createScheduledPayment(payload, onBehalfOfClient.value?.id, {
                errors: { silent: true },
              })
              .toPromise();
          }
          throw 'No wallet found for client';
        })
        .then(() => tradeState.store.useWalletsStore().waitForWalletChange({ id: state.tradeDetails!.walletId! }))
        .then(
          () => {
            tradeState.toast.success('Trade and Payment created successfully');
          },
          (error) => {
            if (error.response?.data.code === PaymentErrorCodes.AGGREGATED_LIMIT_REACHED) {
              tradeState.toast.show(
                'Trade has been created successfully. There was a problem creating your payment. Please go to payments page to review and set up the payment again',
                {
                  title: 'Review',
                  toastType: 'warning',
                  group: 'aggregatedPaymentLimit',
                  actions: [
                    {
                      title: 'Go to payments',
                      method: () => {
                        router.push(aggregatedPaymentReviewRoute);
                        tradeState.toast.clear('aggregatedPaymentLimit');
                      },
                      class: 'btn-primary',
                    },
                  ],
                },
                { noAutoHide: true }
              );
            } else {
              tradeState.toast.error('Funds have been converted, but payment creation has failed');
            }
          }
        )
        .finally(() => clearFieldsAndFinish(tradeVersionedObject))
    );
  }
}

function onTradeConfirmed(bypassOBOConfirm = false) {
  if (isOnBehalfOf.value && props.beneficiary && !bypassOBOConfirm) {
    confirmationModal.value?.show();
    return;
  }

  tradeState.store
    .useAuthStore()
    .checkComplianceApproval({ showModal: true, client: onBehalfOfClient.value ?? undefined })
    .then(() => {
      let createTradeRequest: CreateTradeRequest = {
        priceId: state.tradePrice?.response!.id!,
        hedgingProduct: state.tradePrice?.instrument!,
        syncPendingFunds:
          state.walletBalanceUsageRequired && isVerifyingLimits.value
            ? state.walletBalanceUsageRequired
            : isSyncTradeFunds.value,
      };

      const { valid, ...details } = state.tradeFundingDetails;
      createTradeRequest = { ...createTradeRequest, ...details };

      let hasWaitForTradeFailed = false;

      requestManager.manager
        .sameOrCancelAndNew(
          'createTrade',
          tradeState.services.trade
            .createTrade(createTradeRequest, onBehalfOfClient.value?.id, {
              errors: {
                errorMessages: [
                  {
                    message: `Client limit profile not found`,
                    name: 'client-limit-profile-not-found',
                    code: (response) => response?.data?.code === TradingManagerErrorCodes.LIMITS_PROFILE_MISSING,
                    toastType: 'danger',
                  },
                  {
                    message: `The quoted price for this trade has expired. Please refresh the price quote and try again.`,
                    name: 'trade-price-expired',
                    code: (response) => response?.data?.code === TradingManagerErrorCodes.PRICE_EXPIRED,
                    toastType: 'danger',
                  },
                ],
              },
            })
            .pipe(
              mergeMap((idEntity) =>
                waitForEntityChange(
                  () => tradeState.services.trade.getTrade(idEntity.id, { errors: { silent: true } }),
                  (trade) => ![TradeStatus.SUBMITTED, TradeStatus.VERIFIED].includes(trade.status),
                  {
                    ignoreErrors: (error: HttpError) => error.response?.status === 404,
                    retries: 5,
                    checkContinueWithLastRetry: (response) => {
                      hasWaitForTradeFailed = true;

                      return response;
                    },
                  }
                )
              )
            ),
          createTradeRequest
        )
        .subscribe((trade) => {
          if (state.tradeDestination) {
            let payload: CreatePaymentRequest = {
              beneficiaryId: state.tradeDestination.beneficiary?.id,
              reference: state.tradeDestination.reference,
              chargeType: state.tradeDestination.chargeType,
              reason: state.tradeDestination.reason,
            };
            if (isScheduledPayment.value) {
              // if current trade is a payment we should
              // make an withdraw after the trade is complete with the selected execution
              // date (always set to after the settlement date).
              payload.executionDate = state.tradeDestination!.executionDate
                ? formatDate(state.tradeDestination!.executionDate, 'yyyy-MM-dd')
                : formatDate(state.tradePrice?.response?.settlementDate, 'yyyy-MM-dd');

              scheduleTradePayment(payload, { version: trade.version, id: trade.id });
            } else if (
              state.tradeDestination?.destination === TradeFundsDestination.SEND &&
              !isScheduledPayment.value
            ) {
              // if current trade has a linked payment
              // create a payment linked to the trade
              payload.tradeId = trade.id;
              scheduleTradePayment(payload, { version: trade.version, id: trade.id });
            } else {
              clearFieldsAndFinish({ version: trade.version, id: trade.id });
            }
            handleTradeStatus(trade, hasWaitForTradeFailed);
          }
        });
    });
}

function handleTradeStatus(trade: Trade, hasWaitForTradeFailed: boolean) {
  if (trade.status === TradeStatus.REJECTED) {
    tradeState.toast.error('Trade Rejected');
  } else if (hasWaitForTradeFailed) {
    tradeState.toast.info(
      'Please check your Open Trades page shortly to confirm if it has been created successfully.',
      { title: 'Trade is taking longer than expected to process' }
    );
  } else {
    tradeState.toast.success('The trade was successfully created.', { title: 'Trade Created Successfully' });
  }
}

function goBack() {
  router.push('./');
}

function onWalletBalanceUdageUpdate(event: boolean | null) {
  state.walletBalanceUsageRequired = event;
}

const preferredClientBankType = computed(() => props.bankType);
</script>

<template>
  <div
    :class="{
      'modal-showing': !isInModal && (state.routeLeaveConfirmShowing || state.confirmShowing),
    }"
  >
    <LoadingOverlay
      :variant="(isInModal && 'box') || 'main'"
      :opacity="(isInModal && '0.8') || '1'"
      :state="loadRequestStates"
      show-retry
      @retry="onRetryLoad"
    >
      <!-- title -->
      <Transition name="fade" appear mode="out-in">
        <div v-if="state.stage === 'tradeDetails'" key="tradeDetails">
          <VRow v-if="title" class="mx-0 trade-creator-title-row" align-v="center">
            <h2 class="trade-creator-title">{{ title }}</h2>
          </VRow>
          <BackButton v-if="onBehalfOfClient && !isInModal" label="go back" small @click="goBack()" />
          <TradeFormGroup
            :tradePrice.sync="state.tradePrice"
            :tradeDestination.sync="state.tradeDestination"
            :tradeDetails.sync="state.tradeDetails"
            :costTransparencyCurrency.sync="costTransparencyCurrency"
            @trade-submitted="onTradeSubmitted"
            @update:walletBalanceUsageRequired="onWalletBalanceUdageUpdate"
            :narrow="isInModal || isStackedView"
            :allowedBuyCurrencies="allowedBuyCurrencies"
            :allowedSellCurrencies="allowedSellCurrencies"
            :beneficiary="beneficiary"
            :showCancel="isInModal || isStackedView"
            :forceKeep="isForceKeep"
            :forceShowDestination="isForceShowDestination"
            :forceImmediateSpot="isForceImmediateSpot"
            :syncTradeFunds="isSyncTradeFunds"
            :verifyLimits="verifyLimits"
            :costTransparency="costTransparency"
            @trade-cancelled="onTradeCancelled"
            @update:beneficiary="emit('update:beneficiary', $event)"
            :isPayment="isPayment"
            :bankType="preferredClientBankType"
          />
        </div>
        <div v-else key="tradeConfirmation">
          <VRow class="mx-0" align-v="center">
            <h2 class="mb-1">Review and Execute</h2>
          </VRow>
          <div v-if="onBehalfOfClient" class="review-text d-flex-inline text-secondary mb-4">
            <p>
              Please review all trade details before confirming this trade on behalf of
              <span class="client-name secondary">{{ onBehalfOfClient.name }}</span
              >.
            </p>
          </div>
          <p v-else class="text-secondary mt-n2 mb-4">Please review all trade details before confirming</p>
          <TradeConfirmation
            v-if="state.tradePrice && state.tradeDestination"
            :loading="
              requestManager.manager.requestStates.createTrade === 'pending' ||
              requestManager.manager.requestStates.schedulePayment === 'pending'
            "
            @trade-confirmed="onTradeConfirmed"
            @outdated-price="onOutdatedPrice"
            @revert-to-form="onRevertToForm"
            :tradePrice.sync="state.tradePrice"
            :syncTradeFunds="isSyncTradeFunds"
            :tradeDestination="state.tradeDestination"
            :tradeFundingDetails.sync="state.tradeFundingDetails"
            :narrow="isInModal || isStackedView"
            :costTransparencyCurrency.sync="costTransparencyCurrency"
            :costTransparency="costTransparency"
          />
        </div>
      </Transition>
      <div v-if="!isInModal && (state.routeLeaveConfirmShowing || state.confirmShowing)" class="obo-backdrop" />
      <RouteProtectorModal
        :allowChange="
          !tradeState.store.useAuthStore().isLoggedIn || (!state.tradeDetails?.dirty && !state.tradeDestination?.dirty)
        "
        :allowSubpaths="true"
        :allowQueryChange="false"
        ref="routeLeaveConfirmModal"
        class="exit-protected-route"
        centered
        ok-title="Confirm"
        title="Changes not saved"
        v-model="state.routeLeaveConfirmShowing"
        :hide-backdrop="!!isOnBehalfOf"
      >
        <slot name="leave-confirm-text">
          <p class="text-center px-3">{{ warningMessage }}</p>
        </slot>
      </RouteProtectorModal>
      <BModal
        ref="confirmationModal"
        centered
        ok-title="Confirm"
        @ok="onTradeConfirmed(true)"
        :hide-backdrop="!!isOnBehalfOf"
        v-model="state.confirmShowing"
      >
        <slot name="leave-confirm-text">
          <p class="text-center px-3">{{ oboTradeConfirmationMessage }}</p>
        </slot>
      </BModal>
    </LoadingOverlay>
  </div>
</template>

<style lang="scss" scoped>
.review-text {
  width: 25.375rem;
}

.client-name,
.back-button {
  @include themedTextColor($color-dark-primary, $color-primary);

  &.secondary {
    @include themedTextColor($color-dark-primary, $color-dark-text);
  }
}

.modal-showing {
  filter: blur(14px);
}

.obo-backdrop {
  position: absolute;
  z-index: 1;
  overflow: visible;
  top: -$padded-space;
  left: -$padded-space;
  min-width: calc(100% + #{$padded-space * 2});
  min-height: calc(100% + #{$padded-space});
  @include themedBackgroundColor($color-widgets-green, $color-widgets-green);
  opacity: 0.05;
}
</style>
